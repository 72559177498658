import React from "react"
import { Helmet } from "react-helmet"
import "../styles/style.css"
import { graphql, Link, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Header from "../components/header"
import Footer from "../components/footer"

const Policy = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "cabecera-qualoom.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Política de seguridad | Qualoom</title>
        <meta name="title" content="Política de seguridad | Qualoom"/>
        <meta name="description" content="En Qualoom garantizamos confidencialidad, integridad y disponibilidad en tu política de seguridad, con enfoque en ISO-IEC 27001 y gestión de riesgos."/>
        <html lang='es'/>        
        <link rel="alternate" href="https://www.qualoom.es/politica-de-seguridad/"/>
        <link rel="canonical" href="https://www.qualoom.es/politica-de-seguridad/"/>
        <meta name="robots" content="noindex,nofollow"/>
      </Helmet>
      <BackgroundImage fluid={data.image.childImageSharp.fluid}>
        <div className="background-legal">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Política de seguridad</h1>
              <p>En Qualoom garantizamos confidencialidad, integridad y disponibilidad en tu política de seguridad, con enfoque en ISO-IEC 27001 y gestión de riesgos.</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="content">
        <h2>Política de Seguridad</h2><hr></hr>
        <p>Qualoom es una compañía española cuyo objeto es la prestación de servicios relacionados con los sistemas y tecnologías de la información y las comunicaciones. Qualoom ofrece a sus clientes, desde el inicio de su actividad, servicios de consultoría de procesos y administración de sistemas de información, tecnología informático, asistencia técnica y auditorías.</p>
        <p>Actualmente, Qualoom tiene su sede central en Madrid. Se puede obtener más información en <Link to="/">www.qualoom.es</Link></p>
        <p>Forma parte esencial del punto de partida de la compañía ofrecer sus servicios con la máxima calidad y seguridad con el coste más competitivo posible, estableciendo con los clientes una relación de confianza y compromiso plenos. Forma también parte esencial del espíritu de Qualoom ofrecer a sus empleados un lugar idóneo en el que desarrollar de forma satisfactoria su carrera profesional.</p>
        <p>Los valores fundamentales que Qualoom impulsa como propios son los siguientes:</p>
        <ul>
          <li><b>Profesionalidad y eficacia. </b>La oferta que la compañía hace a sus clientes es la de un servicio profesional y eficaz y éstas son las cualidades que sus integrantes deben aportar y en las que deben progresar.<br/>La base del cumplimiento de los compromisos de Qualoom debe ser la mejora continua de sus habilidades, procedimientos y herramientas y no el voluntarismo, ni el sobreesfuerzo sostenido.</li>
          <li><b>Iniciativa y proactividad. </b>Qualoom busca y fomenta la iniciativa en sus integrantes, de forma que todos aporten ideas y energía innovadora, tanto en el área comercial, como en el desarrollo de los proyectos o en el funcionamiento y organización de la propia empresa.<br/>Se espera de todos los empleados de Qualoom una actitud permanente de anticipación a los problemas y una capacidad para, no sólo realizar el trabajo asignado, sino también identificar y asumir de manera autónoma aquellas tareas que conduzcan al éxito de los proyectos. </li>
          <li><b>Estructura ligera de supervisión. </b>En Qualoom se fomenta la delegación de la capacidad de decisión hacia las personas más próximas a los problemas. Esto permite mantener una estructura jerárquica ligera, de forma que la mayor parte del trabajo realizado sea productivo.<br/>Forma parte de la esencia del modelo de empresa de Qualoom maximizar el tiempo cargable a clientes y minimizar el esfuerzo de supervisión y administración. </li>
          <li><b>Comunicación total. </b>La comunicación debe fluir sin obstáculos tanto en sentido descendente como ascendente de forma que cada miembro de la empresa conozca en todo momento, por un lado, el objeto de su trabajo, la finalidad de su proyecto, la estrategia con su cliente y la marcha de la compañía y, por otro lado, que todos los empleados puedan hacer llegar a quien corresponda sus ideas, sugerencias o quejas. </li>
          <li><b>Garantizar la confidencialidad, integridad y disponibilidad de la información. </b>De acuerdo con el punto anterior, el intercambio de información ha de ser fluido y ágil pero siempre por medio de canales seguros y de confianza, para minimizar posibles fugas de información, conforme a la cláusula de confidencialidad que todos los empleados firman. De modo opuesto, nuestros proveedores tienen el acceso limitado a aquellos datos imprescindibles para la prestación de su servicio y siempre bajo la supervisión de Qualoom. </li>
        </ul>
        <p>Para alcanzar un nivel de calidad y seguridad, Qualoom mantiene un sistema de seguridad de la información ISO-IEC 27001 mediante el cual se compromete a:</p>
        <ul>
          <li>Exceder las expectativas de los clientes durante la prestación de los servicios, mejorando la generación de relaciones de confianza y compromiso a largo plazo.</li>
          <li>Dar cumplimiento a los requisitos solicitados por el cliente, así como a los requisitos legales, reglamentarios u obligaciones contractuales que se apliquen en cada caso (prevención de riesgos, medio ambiente, calidad, etc.)</li>
          <li>Garantizar la confidencialidad, integridad, disponibilidad, trazabilidad y autenticidad en el tratamiento de la información de los clientes.</li>
          <li>Mejorar y optimizar los procesos operativos y de gestión de Qualoom, mediante el análisis de los resultados obtenidos, la incorporación de nuevas formas y metodologías de trabajo y la capacitación profesional de sus trabajadores.</li>
          <li>Mejorar continuamente la eficacia del sistema de gestión</li>
          <li>Proporcionar un marco de referencia para el establecimiento de los objetivos de cada uno de los sistemas.</li>
          <li>Cumplir con la Normativa aplicable en cada uno de sus ámbitos y los requisitos pertinentes en materia de cada sistema de gestión.</li>
          <li>Identificar y evaluar los impactos ambientales con el objetivo de implantar medidas que sirvan para prevenir la contaminación, optimizar el uso de recursos y minimizar la generación de residuos.</li>
          <li>Gestionar los residuos adecuadamente para posibilitar el reciclado cuando sea posible, prevenir la contaminación y proteger el medio ambiente.</li>
          <li>Proteger, mediante controles/medidas, los activos frente a amenazas que puedan derivar en incidentes de seguridad.</li>
          <li>Evaluar de forma periódica los riesgos que afectan a los activos, en base a los criterios definidos, con el objetivo de gestionarlos de forma eficiente y adoptar las medidas/controles oportunos sobre ellos.</li>
          <li>Los responsables de cada información quedarán definidos en el análisis de riesgos dónde se valora cada activo.</li>
          <li>Proporcionar condiciones de trabajo seguras y saludables a nuestros trabajado- res: Aportándoles el conocimiento necesario para que puedan desempeñar su trabajo en las condiciones adecuadas.</li>
          <li>Informar y formar a los trabajadores sobre los riesgos inherentes a su trabajo y las medidas a adoptar para su correcta prevención, para permitir el desarrollo de la política de prevención.</li>
          <li>Conseguir un entorno de trabajo seguro, eliminando los peligros/riesgos laborales y controlando y/o minimizando los riesgos que no se pueden eliminar.</li>
          <li>Establecer mecanismos para la consulta y participación de los trabajadores y cuando existan los representantes de los trabajadores.</li>
          <li>Prevenir las lesiones y deterioro de la salud relacionadas con el trabajo a través de acciones y que estas sean apropiadas al propósito, tamaño y contexto de Qualoom.</li>
        </ul>
        <p>Qualoom, como empresa con una dilatada experiencia en el ámbito de los sistemas de información, es consciente de la importancia de la seguridad y la adecuada protección de la información para el correcto desempeño de sus funciones de negocio, el cumplimiento de sus objetivos y satisfacción de sus grupos de interés, por ello se fija un marco de actuación para proteger los sistemas de información y las comunicaciones de amenazas internas o externas, deliberadas o accidentales en sus tres pilares fundamentales; confidencialidad, integridad y disponibilidad.</p>
        <p>Esta seguridad está basada en los principios de:</p>
        <ul>
          <li><b>Seguridad Integral: </b>La seguridad se entenderá como un proceso integral constituido por todos los elementos técnicos, humanos, materiales y organizativos, relacionados con el sistema. Se prestará la máxima atención a la concienciación de las personas que intervienen en el proceso y a sus responsables jerárquicos, para que, ni la ignorancia, ni la falta de organización y coordinación, ni instrucciones inadecuadas, sean fuentes de riesgo para la seguridad.</li>
          <li><b>Gestión de Riesgos: </b>El análisis y gestión de riesgos es parte esencial del proceso de seguridad y se mantendrá permanentemente actualizado. Permitirá minimizar los riesgos hasta niveles aceptables. Mediante el despliegue de medidas de seguridad, que establecerá un equilibrio entre la naturaleza de los datos y los tratamientos, los riesgos a los que estén expuestos y las medidas de seguridad.</li>
          <li><b>Prevención, reacción y recuperación: </b>Qualoom dispone de mecanismos de detección y prevención de incidentes y tiene la capacidad de responder lo más rápidamente posible ante los mismos.</li>
          <li><b>Líneas de defensa: </b>Disponemos de una estrategia de protección constituida por múltiples capas de seguridad y un departamento encargado del mantenimiento de estas.</li>
          <li><b>Reevaluación periódica: </b>A través de auditorías internas y externas</li>
          <li><b>Función diferenciada: </b>Existirán roles diferencias dentro del Sistema de Seguridad, Sistemas TI y Sistema de Gestión del Sistema de Información.</li>
        </ul>
        <p>Qualoom asume la responsabilidad de comunicar y explicar la política de seguridad de la información a todo el personal de Qualoom, sea propio o esté subcontratado temporalmente, para que la conozca y entienda. Asimismo, Qualoom se compromete a asegurar que la política está siempre a disposición de cualquier persona externa a la organización que desee tener acceso a ella.</p>
        <p>El incumplimiento de estas obligaciones por parte del personal podrá dar lugar a responsabilidades disciplinarias y al ejercicio de los procedimientos legales de la empresa. La organización podrá aplicar sanciones administrativas además de las previstas por la ley. Cuando se produzcan conflictos de competencias entre los distintos responsables, será resuelto por el superior jerárquico.</p>
        <p>Qualoom se compromete a revisar periódicamente la política de seguridad para su correcta adecuación a lo largo del tiempo y asegura que, aunque se produzcan cambios en ella, no se alterará la coherencia del sistema de gestión de la seguridad de la información implantada.</p>
      </div>
      <Footer />
    </div>
  )
}

export default Policy
